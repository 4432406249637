import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import * as React from 'react';

import ReactCrop from 'react-image-crop';

import "react-image-crop/dist/ReactCrop.css";
import styles from './ProfilePictureCrop.module.css';

export interface ProfilePictureCropProps {
  image: string;
  onCropComplete: (image: File, url: string) => void;
  onCancel: () => void;
  isOpen: boolean;
}

const ProfilePictureCrop: React.FC<ProfilePictureCropProps> = (props) => {
  const imageRef = React.useRef<any>(null);
  const [crop, setCrop] = React.useState<ReactCrop.Crop>({ aspect: 1 });
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);

  const onImageLoaded = (image: any) => {
    imageRef.current = image;
  }

  const onCropChange = (crop: ReactCrop.Crop) => {
    setCrop({ ...crop, aspect: 1 });
  }

  const onCropComplete = (crop: ReactCrop.Crop) => {
    if (imageRef && crop.width && crop.height) {
      finishCrop(imageRef.current, crop)
    }
  }

  const dataURLtoFile = (dataurl: string, filename: string) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)![1]!,
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage;
  }

  const finishCrop = (image: any, crop: ReactCrop.Crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext("2d")!;

    ctx.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      if (!blob)
        return;

      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        let img = dataURLtoFile(reader.result as string, 'cropped.jpg');
        let url = URL.createObjectURL(blob);

        setImageFile(img);
        setImageUrl(url);
      }
    })
  }

  const onCancel = () => {
    setCrop({aspect: 1});
    setImageFile(null);
    setImageUrl(null);
    props.onCancel();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crop Picture</ModalHeader>
        <ModalCloseButton onClick={onCancel} />
        <ModalBody>
          <div className={styles.container}>
            <ReactCrop
              className={styles.crop}
              maxHeight={(imageRef.current?.naturalHeight ?? 513) > 512 ? 512 : imageRef.current!.naturalHeight}
              maxWidth={(imageRef.current?.naturalWidth ?? 513) > 512 ? 512 : imageRef.current!.naturalWidth}
              minWidth={128}
              minHeight={128}
              src={props.image}
              onImageLoaded={onImageLoaded}
              crop={crop}
              onChange={onCropChange}
              onComplete={onCropComplete}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3}
            isDisabled={!imageFile || !imageUrl}
            onClick={() => props.onCropComplete(imageFile!, imageUrl!)}
          >
            Save
            </Button>
          <Button variant="ghost" onClick={onCancel}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ProfilePictureCrop;