import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react';
import * as React from 'react';

export interface DialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  title: string;
  message: string;
}

const Dialog: React.FC<DialogProps> = (props) => {
  const cancelRef = React.useRef<any>(null)

  return (
    <>
      <AlertDialog
        isOpen={props.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={props.onCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {props.title}
            </AlertDialogHeader>

            <AlertDialogBody>
              {props.message}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={props.onCancel}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={props.onOk} ml={3}>
                Ok
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default Dialog;