import { Heading } from '@chakra-ui/react';
import * as React from 'react';

import styles from './Homepage.module.css';

const Homepage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Heading as='h1'>Homepage</Heading>
      <p>Check the top left to explore the site!</p>
    </div>
   );
}

export default Homepage;