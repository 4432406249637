import { Heading } from '@chakra-ui/react';
import * as React from 'react';

const NotFound: React.FC = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <Heading as='h1'>The page you are looking for doesn't exist.</Heading>
    </div>
  );
}

export default NotFound;
