export const timeSince = (date: Date) => {
  const now = new Date();
  const utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate() ,
  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())
  const seconds = Math.floor((utc_timestamp - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return  returnInterval + " year" + (returnInterval > 1 ? "s": "");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " month" + (returnInterval > 1 ? "s": "");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " day" + (returnInterval > 1 ? "s": "");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " hour" + (returnInterval > 1 ? "s": "");
  }
  interval = seconds / 60;
  if (interval > 1) {
    const returnInterval = Math.floor(interval)
    return returnInterval + " minute" + (returnInterval > 1 ? "s": "");
  }

  interval = seconds / 60;
  const returnInterval = Math.floor(interval)
  return returnInterval + " second" + (returnInterval > 1 ? "s": "");
}