import * as React from 'react';
import { FormControl, Input, Text, Button } from '@chakra-ui/react';

import styles from './SignUpToken.module.css';
import { useHistory } from 'react-router-dom';

const SignUpToken: React.FC = () => {
  const [token, setToken] = React.useState("");

  const history = useHistory();

  const onTokenChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value);
  }

  const onTokenSubmit = () => {
    history.push(`/signup/${token}`);
  }

  return (
    <div className={styles.content}>
      <Text textAlign='center' fontSize="3xl">If you've received an invite via email, use your invite token here!</Text>
      <FormControl>
        <Input
          className={styles.tokenInput}
          placeholder="Your Invite Token"
          value={token}
          onChange={onTokenChange}
        />
      </FormControl>
      <Button onClick={onTokenSubmit} colorScheme='green' >Submit</Button>
    </div>
  );
}

export default SignUpToken;