import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from "../components/Spinner/LoadingSpinner";

const PrivateRoute: React.FC<RouteProps> = (props) => (
  <Route
    {...props}
    component={withAuthenticationRequired(props.component! as React.ComponentType<RouteComponentProps<any>>, {
      onRedirecting: () => <LoadingSpinner />,
    })}
  />
);

export default PrivateRoute;

