import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerOverlay, Image, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Button, MenuDivider } from '@chakra-ui/react';
import { ArrowRightIcon, TriangleDownIcon } from '@chakra-ui/icons';
import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import logo from '../../images/Logo.svg';

import styles from './MobileHeader.module.css'

const MobileHeader: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);

  const btnRef = React.useRef<any>();

  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const checkIsAdmin = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}user/hasGroup?group=Admin`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const result = (await res.text()) === 'true';
        setIsAdmin(result);
      }
    });
  }

  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  }


  const onDrawerButtonClick = () => {
    setIsDrawerOpen(true);
  }

  const goToProfile = React.useCallback(() => {
    if (!user)
      return;
    history.push('/user/' + user?.nickname)
  }, [user, history])

  React.useEffect(() => {
    if (isAuthenticated)
      checkIsAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <header className={styles.MobileHeader}>
      {isAuthenticated &&
        <IconButton
          aria-label='drawer'
          icon={<ArrowRightIcon />}
          ref={btnRef}
          className={styles.DrawerButton}
          onClick={onDrawerButtonClick}
        />
      }


      <Image h='2.8em' margin='auto' src={logo} onClick={() => history.push('/')} />

      {isAuthenticated && <>
        <Button colorScheme='green' size='sm' marginRight='5px' onClick={() => history.push('/upload')}>
          Upload
        </Button>

        <Menu>
          <MenuButton as="div" _hover={{ cursor: 'pointer' }}>
            {user && <Avatar size='sm' name={user.nickname} src={user.picture} />}
            {!user && <TriangleDownIcon color='#ffeb38' w='1.5em' />}
          </MenuButton>
          <MenuList>
            <MenuGroup title="Profile">
              <MenuItem onClick={goToProfile}>My Profile</MenuItem>
              <MenuItem onClick={() => history.push('/settings')}>Settings</MenuItem>
              {isAdmin && <MenuItem onClick={() => history.push('/admin')}>Admin</MenuItem>}
            </MenuGroup>

            <MenuDivider />

            <MenuItem onClick={() => {
              logout();
              history.push('/');
            }}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </>
      }

      {!isAuthenticated && <>
        <Button colorScheme='gray' size='sm' marginRight="5px" onClick={() => loginWithRedirect()}>
          Log in
        </Button>

        <Button colorScheme='green' size='sm' onClick={() => history.push('/signup')}>
          Sign Up
        </Button>
      </>}

      <Drawer
        isOpen={isDrawerOpen}
        placement="left"
        onClose={onDrawerClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody className={styles.DrawerBody}>
            <NavLink to='/feed/new' className={styles.Link} onClick={onDrawerClose}>New</NavLink>
            <NavLink to='/feed/top' className={styles.Link} onClick={onDrawerClose}>Top</NavLink>
          </DrawerBody>

          <DrawerFooter>
            <p>Kernbehindert © 2020 - {(new Date()).getFullYear()}</p>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </header >
  );
}

export default MobileHeader;