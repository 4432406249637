import * as React from 'react';
import { Button, Heading, Image, Stack, Tag, TagLabel, Skeleton } from '@chakra-ui/react';

import styles from './Post.module.css';
import { useHistory, useParams } from 'react-router-dom';

import PostType from '../../types/Post';
import LoadingSpinner from '../../components/Spinner/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import HasUserVotedResult from '../../types/HasUserVotedResult';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

interface PostParams {
  id: string;
}

const Post: React.FC = () => {
  const [post, setPost] = React.useState<PostType | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isVotingLoading, setIsVotingLoading] = React.useState(false);
  const [userHasUpvoted, setUserHasUpvoted] = React.useState(false);
  const [userHasDownvoted, setUserHasDownvoted] = React.useState(false);
  const [tags, setTags] = React.useState<string[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  let { id } = useParams<PostParams>();

  const loadPost = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}post/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      const result = await res.json() as PostType;

      setPost(result);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const loadHasUserVoted = async () => {
    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${id}/userHasVoted`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      const result = await res.json() as HasUserVotedResult;

      setUserHasUpvoted(result.userHasUpvoted);
      setUserHasDownvoted(result.userHasDownvoted);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const loadTags = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}post/${id}/tags`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      const result = await res.json() as string[];

      setTags(result)
    }).catch(err => {
      console.log(err);
    });
  }

  const resetVote = async (wasUpvoted: boolean) => {
    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${id}/reset`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        let newPost = { ...post! };
        if (wasUpvoted) {
          newPost.upvoteCount -= 1;
        } else {
          newPost.downvoteCount -= 1;
        }

        setPost(newPost);
        setUserHasUpvoted(false);
        setUserHasDownvoted(false);
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const upvote = async () => {
    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    if (userHasUpvoted) {
      resetVote(true);
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}vote/${id}/up`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        let newPost = { ...post!, upvoteCount: post!.upvoteCount + 1 };
        if (userHasDownvoted) {
          newPost.downvoteCount -= 1;
        }

        setPost(newPost);
        setUserHasUpvoted(true);
        setUserHasDownvoted(false);
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const downvote = async () => {
    if (userHasDownvoted) {
      resetVote(false);
      return;
    }

    setIsVotingLoading(true);
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}vote/${id}/down`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        let newPost = { ...post!, downvoteCount: post!.downvoteCount + 1 };
        if (userHasUpvoted) {
          newPost.upvoteCount -= 1;
        }

        setPost(newPost);
        setUserHasUpvoted(false);
        setUserHasDownvoted(true);
      }
    }).finally(() => {
      setIsVotingLoading(false);
    });
  }

  const handleOnTagClick = (tag: string) => {
    history.push('/feed/' + tag.replaceAll("#", ""));
  }

  React.useEffect(() => {
    loadPost();
    loadHasUserVoted();
    loadTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return <LoadingSpinner />
  else {
    return (
      <div className={styles.Post}>
        <Heading as='h2'>{post!.title}</Heading>
        <div className={styles.BoundingBox}>
          <Image fallback={<Skeleton className={styles.Skeleton} />} className={styles.PostImage} alt='post' src={post!.imageUrl} />
        </div>
        <div className={styles.VotingButtons}>
          <Button isLoading={isVotingLoading} borderColor={userHasUpvoted ? 'black' : 'transparent'} leftIcon={<ArrowUpIcon />} colorScheme='blue' onClick={upvote}>{post?.upvoteCount ?? 0}</Button>
          <Button isLoading={isVotingLoading} borderColor={userHasDownvoted ? 'black' : 'transparent'} leftIcon={<ArrowDownIcon />} colorScheme='orange' onClick={downvote}>{post?.downvoteCount ?? 0}</Button>
        </div>
        <Stack minHeight='2.5em' marginTop='0.5em' spacing={4} isInline flexWrap='wrap'>
          {tags.map(tag => (
            <Tag
              size='md'
              key={tag}
              rounded="full"
              variant="solid"
              colorScheme="cyan"
              margin='0.25em'
              onClick={() => handleOnTagClick(tag)}
            >
              <TagLabel>{tag}</TagLabel>
            </Tag>
          ))}
        </Stack>
      </div>
    );
  }
}

export default Post;