const mimeTypeMap = new Map<string, string>([
  ['image/bmp', '.bmp'],
  ['image/x-bmp', '.bmp'],
  ['image/x-ms-bmp', '.bmp'],
  ['image/fif', '.fig'],
  ['image/gif', '.gif'],
  ['image/ief', '.ief'],
  ['image/jpeg', '.jpg'],
  ['image/png', '.png'],
  ['image/svg+xml', '.svg'],
  ['image/tiff', '.tiff'],
  ['image/x-icon', '.ico'],
])

const fileExtensionsMap = new Map<string, string>([
  ['.bmp', 'image/bmp'],
  ['.bmp', 'image/x-bmp'],
  ['.bmp', 'image/x-ms-bmp'],
  ['.fig', 'image/fif'],
  ['.gif', 'image/gif'],
  ['.ief', 'image/ief'],
  ['.jpg', 'image/jpeg'],
  ['.png', 'image/png'],
  ['.svg', 'image/svg+xml'],
  ['.tiff', 'image/tiff'],
  ['.ico', 'image/x-icon'],
])

export const getFileExtension = (mimeType: string): string => {
  const fileExtensions = mimeTypeMap.get(mimeType);
  return fileExtensions ?? 'dat';
}

export const getMimeType = (extension: string): string => {
  const mimeType = fileExtensionsMap.get(extension);
  return mimeType ?? 'image/jpeg';
}