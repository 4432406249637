import * as React from 'react';

import styles from './SignUp.module.css';
import { Input, Button, FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { useParams, Redirect } from 'react-router-dom';
import { MailFormatValidator } from '../../lib/validator';
import UserSignupData from '../../types/UserSignupData';
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from '../../components/Spinner/LoadingSpinner';

interface SignUpParams {
  token: string;
}

const SignUp: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isTokenValid, setIsTokenValid] = React.useState<boolean>(true);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [repeatedPassword, setRepeatedPassword] = React.useState('');
  const [emailFree, setEmailFree] = React.useState(true);

  const { loginWithRedirect } = useAuth0();

  const { token } = useParams<SignUpParams>();

  const checkToken = async (signUpToken: string) => {
    fetch(`${process.env.REACT_APP_API_URL}token/${signUpToken}/validate`, {
      method: 'GET',
      mode: 'cors'
    }).then(res => {
      setIsTokenValid(res.ok);
      setIsLoading(false);
    }).catch(err => {
      setIsTokenValid(false);
      setIsLoading(false);
    });
  }

  const checkUserExists = () => {
    if (!email) {
      setEmailFree(true);
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}user/existsWithEmail?email=${encodeURI(email)}`, {
      method: 'GET',
      mode: 'cors',
    }).then(async (res) => {
      const response = await res.json();
      setEmailFree(!response);
    });
  }

  const createUser = async () => {
    setIsLoading(true);

    const userSignupData: UserSignupData = {
      email: email,
      password: password,
      token: token
    }

    fetch(`${process.env.REACT_APP_API_URL}user/create`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(userSignupData),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.ok)
        loginWithRedirect({});
    }).finally(() => setIsLoading(false));
  }

  const handleEmailChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value as string);

    if (event.target.value === '')
      setEmailFree(true);
  }

  const handlePasswordChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value as string);
  }

  const handleRepeatedPasswordChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setRepeatedPassword(event.target.value as string);
  }

  const validContent = (
    <FormControl onSubmit={createUser} className={styles.form}>
      <FormLabel className={styles.label} htmlFor="email">E-Mail</FormLabel>
      <Input
        isInvalid={email !== '' && !MailFormatValidator(email)}
        type='email'
        id="email"
        value={email}
        aria-describedby='email-helper-text'
        onChange={handleEmailChange}
        onBlur={checkUserExists}
      />
      <FormHelperText id="email-helper-text" display={!email || emailFree ? 'none' : undefined} color='red'>
        This email is taken.
      </FormHelperText>

      <FormLabel className={styles.label} htmlFor="password">Password</FormLabel>
      <Input
        type='password'
        isInvalid={repeatedPassword !== password}
        id="password"
        value={password}
        onChange={handlePasswordChange}
      />

      <FormLabel className={styles.label} htmlFor="rpasswaord">Repeat your Password</FormLabel>
      <Input
        type='password'
        isInvalid={repeatedPassword !== password}
        id="rpassword"
        value={repeatedPassword}
        onChange={handleRepeatedPasswordChange}
        isRequired
      />

      <Button type='submit' onClick={createUser} className={styles.submitButton}>
        Submit
      </Button>
    </FormControl>
  );

  React.useEffect(() => {
    checkToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content = null;

  if (isLoading)
    content = <LoadingSpinner />;
  else if (isTokenValid)
    content = validContent;
  else
    content = <Redirect to="/signup/invalid" />;

  return (
    <div className={styles.SignUp}>
      {content}
    </div>
  );
}

export default SignUp;