import * as React from 'react';

import styles from './Feed.module.css'
import { List, ListItem } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import PostType from '../../types/Post';
import FeedPost from './FeedPost/FeedPost';
import InfiniteScroll from 'react-infinite-scroll-component';
import Dialog from '../../components/Dialog/Dialog';

export interface UserFeedProps {
  userId: string;
}

const UserFeed: React.FC<UserFeedProps> = (props) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [posts, setPosts] = React.useState<PostType[]>(new Array<PostType>());
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isHideDialogOpen, setIsHideDialogOpen] = React.useState(false);

  //Not very pretty but will do the job
  const [postToHideOrDelete, setPostToHideOrDelete] = React.useState<number | undefined>();

  const checkIsAdmin = async () => {
    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}user/hasGroup?group=Admin`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      if (res.ok) {
        const result = (await res.text()) === 'true';
        setIsAdmin(result);
      }
    });
  }

  const loadPosts = async (page: number) => {
    const token = await getAccessTokenSilently();

    return await fetch(`${process.env.REACT_APP_API_URL}post/feed/user/${props.userId}?page=${page}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(async (res) => {
      var result = await res.json() as PostType[];
      setHasMoreData(result.length >= 25);
      return result;
    });
  }

  const refresh = async () => {
    setCurrentPage(1);
    const posts = await loadPosts(1);
    setPosts(posts);
  }

  const handlePostUpdate = (post: PostType) => {
    const postIndex = posts.findIndex(m => m.id === post.id);
    posts.splice(postIndex, 1, post);
    setPosts([...posts]);
  }

  const getNextPage = async () => {
    const page = currentPage + 1;
    setCurrentPage(page);
    const newPosts = await loadPosts(page);
    setPosts(prev => [...prev, ...newPosts])

  }

  const deletePost = (id: number) => {
    setPostToHideOrDelete(id);
    setIsDeleteDialogOpen(true);
  }

  const onCancelDeletePost = () => {
    setPostToHideOrDelete(undefined);
    setIsDeleteDialogOpen(false);
  }

  const onConfirmDeletePost = async () => {
    const token = await getAccessTokenSilently();
    fetch(`${process.env.REACT_APP_API_URL}post/${postToHideOrDelete!}/delete`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setIsDeleteDialogOpen(false);
    removePost(postToHideOrDelete!);
    setPostToHideOrDelete(undefined);
  }

  const hidePost = (id: number) => {
    setPostToHideOrDelete(id);
    setIsHideDialogOpen(true);
  }

  const onCancelHidePost = () => {
    setPostToHideOrDelete(undefined);
    setIsHideDialogOpen(false);
  }

  const onConfirmHidePost = async () => {
    const token = await getAccessTokenSilently();
    fetch(`${process.env.REACT_APP_API_URL}post/${postToHideOrDelete!}/hide`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setIsHideDialogOpen(false);
    removePost(postToHideOrDelete!);
    setPostToHideOrDelete(undefined);
  }

  const removePost = (id: number) => {
    const index = posts.findIndex(m => m.id === id);
    posts.splice(index, 1);
    setPosts([...posts]);
  }

  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId])

  React.useEffect(() => {
    checkIsAdmin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.Feed}>
      <Dialog
        isOpen={isHideDialogOpen}
        message='Do you want to hide this post?'
        title='Hide Post'
        onCancel={onCancelHidePost}
        onOk={onConfirmHidePost}
      />

      <Dialog
        isOpen={isDeleteDialogOpen}
        message='Do you want to delete this post?'
        title='Delete Post'
        onCancel={onCancelDeletePost}
        onOk={onConfirmDeletePost}
      />

      <InfiniteScroll
        dataLength={posts.length} //This is important field to render the next data
        next={getNextPage}
        hasMore={hasMoreData}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Nothing to see here!</b>
          </p>
        }
        refreshFunction={refresh}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        }
      >
        <List >
          {posts.length > 0 && posts.map(post => {
            return (
              <ListItem key={post.id}>
                <FeedPost
                  setPost={handlePostUpdate}
                  post={post}
                  onPostDelete={deletePost}
                  onPostHide={hidePost}
                  isAdmin={isAdmin}
                  isOwnPost={post.userId === user?.sub}
                />
              </ListItem>
            )
          })}
        </List>
      </InfiniteScroll>
    </div>
  );
}

export default UserFeed;