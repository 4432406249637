import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserSettings from '../containers/UserSettings/UserSettings';
import Upload from '../containers/Upload/Upload';
import SignUp from '../containers/SignUp/SignUp';
import Feed from '../containers/Feed/Feed';
import SignUpToken from '../containers/SignUp/SignUpToken/SignUpToken';
import InvalidToken from '../containers/SignUp/InvalidToken/InvalidToken';
import PrivateRoute from './PrivateRoute';
import Post from '../containers/Post/Post';
import Admin from '../containers/Admin/Admin';
import Homepage from '../containers/Homepage/Homepage';
import UserPage from '../containers/UserPage/UserPage';

const CustomSwitch: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute component={Feed} path='/feed/:tag' />
      <PrivateRoute component={UserSettings} path='/settings' />
      <PrivateRoute component={Upload} path='/upload' />
      <PrivateRoute component={UserPage} path='/user/:username' />
      <Route component={Admin} path='/admin' />
      <Route component={InvalidToken} exact path='/signup/invalid' />
      <Route component={SignUp} path='/signup/:token' />
      <Route component={SignUpToken} path='/signup' />
      <Route component={Post} path='/post/:id' />
      <Route exact component={Homepage} path='/' />
    </Switch>
  );
}

export default CustomSwitch;