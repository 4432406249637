import * as React from 'react';
import { Heading, Text } from '@chakra-ui/react';

import styles from './InvalidToken.module.css';
import { Link } from 'react-router-dom';

const InvalidToken: React.FC = () => {
  return (
    <div className={styles.content}>
      <Heading as="h1" size="2xl">
        Oops!
      </Heading>
      <Text fontSize="3xl">
        It seems like your token does not exist or is used already!
      </Text>
      <Text fontSize="3xl">
        Please check your email and <Link style={{color:"teal"}} to="/signup">try again</Link>.
      </Text>
    </div>
  );
}

export default InvalidToken;