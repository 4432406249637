import * as React from 'react';
import { Alert, AlertIcon, Button, Image, Input, Text } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react';

import styles from './UserSettings.module.css';
import LoadingSpinner from '../../components/Spinner/LoadingSpinner';
import ProfilePictureCrop from './ProfilePictureCrop/ProfilePictureCrop';

const Profile: React.FC = () => {
  const [nickname, setNickname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailVerified, setEmailVerified] = React.useState(false);
  const [isProfilePictureCropOpen, setIsProfilePictureCropOpen] = React.useState(false);
  const [isPasswordChangeLoading, setIsPasswordChangeLoading] = React.useState(false);
  const [passwordChangeRequested, setPasswordChangeRequested] = React.useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = React.useState('');
  const [profilePictureCropUrl, setProfilePictureCropUrl] = React.useState('');
  const [profilePictureFile, setProfilePictureFile] = React.useState<File | null>(null);

  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  const changePassword = async () => {
    setIsPasswordChangeLoading(true);

    fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user!.email,
        connection: 'Username-Password-Authentication'
      })
    }).then(res => {
      if (res.ok)
        setPasswordChangeRequested(true);
    }).finally(() => {
      setIsPasswordChangeLoading(false);
    });
  }

  const handleNicknameChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
  }

  const handleEmailChange = (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleFileChange = async (event: React.FormEvent<any> & React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files![0])
      return;

    setIsProfilePictureCropOpen(true)
    setProfilePictureCropUrl(URL.createObjectURL(event.target.files[0]));
  }

  const onProfilePictureCrop = (img: File, url: string) => {
    setProfilePictureUrl(url);
    setProfilePictureFile(img);
    setIsProfilePictureCropOpen(false);
  }

  const updateUserProfile = async () => {
    const postData = new FormData();

    if (email !== user!.email) {
      setEmailVerified(false);
      postData.append("email", email);
    }

    if (profilePictureFile)
      postData.append("picture", profilePictureFile);

    if (nickname !== user!.nickname)
      postData.append("nickname", nickname);

    let hasEntries = false;
    postData.forEach(m => {
      hasEntries = true;
    });

    if (!hasEntries)
      return;

    const token = await getAccessTokenSilently();

    fetch(`${process.env.REACT_APP_API_URL}user/update`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body: postData
    }).catch(err => {
      console.log(err);
    })
  }

  React.useEffect(() => {
    if (!user)
      return;

    setNickname(user!.nickname!);
    setEmail(user!.email!);
    setEmailVerified(user!.email_verified!)
    setProfilePictureUrl(user!.picture!)
  }, [user]);

  if (isLoading)
    return <LoadingSpinner />

  return (
    <div className={styles.Profile}>
      <ProfilePictureCrop
        isOpen={isProfilePictureCropOpen}
        image={profilePictureCropUrl}
        onCropComplete={onProfilePictureCrop}
        onCancel={() => setIsProfilePictureCropOpen(false)}
      />

      <div className={styles.Row}>
        <Image w='10em' h='10em' src={profilePictureUrl} alt="Profile" />
      </div>
      <label htmlFor="file-upload" className={styles.PictureInput}>
        Change Profile Picture
      </label>
      <Input id='file-upload' onChange={handleFileChange} accept='image/*' type='file'></Input>
      <form onSubmit={(event) => {
        event.preventDefault();
        updateUserProfile();
      }}>
        <div className={styles.Row}>
          <Text className={styles.RowText}>Nickname</Text>
          <Input type='text' value={nickname} onChange={handleNicknameChange}></Input>
        </div>
        <div className={styles.Row}>
          <Text className={styles.RowText}>Email</Text>
          <Input type='text' value={email} onChange={handleEmailChange}></Input>
        </div>
        {!emailVerified &&
          <Alert status="warning" marginTop='0.5em'>
            <AlertIcon />
            Your email is not verfied
          </Alert>
        }
        <div className={styles.Row}>
        <Text className={styles.RowText}>Password</Text>
          <Button onClick={changePassword} isLoading={isPasswordChangeLoading}>
            Change Password
          </Button>
        </div>

        {passwordChangeRequested &&
          <Alert status="info" marginTop='0.5em'>
            <AlertIcon />
            Check your mails!
          </Alert>
        }
        <div className={styles.Row}>
          <Button className={styles.SubmitButton} colorScheme='green' type='submit'>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Profile;