import * as React from 'react';
import { Spinner } from '@chakra-ui/react';

const LoadingSpinner: React.FC = () => {
  return (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
      top='50%'
      left='50%'
      transform='translate(-50%, -50%)'
      position='absolute'
    />
  );
}

export default LoadingSpinner;