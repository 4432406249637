import * as React from 'react';

import { theme, ChakraProvider  } from '@chakra-ui/react';
import Layout from '../Layout/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const App: React.FC = () => {
  const { isLoading } = useAuth0();

  return (
    <Router>
      <ChakraProvider theme={theme}>
        {isLoading ? <LoadingSpinner /> : <Layout />}
      </ChakraProvider>
    </Router>
  );
}

export default App;
